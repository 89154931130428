module.exports={
  "name": "socket.io-client",
  "version": "2.5.0",
  "keywords": [
    "realtime",
    "framework",
    "websocket",
    "tcp",
    "events",
    "client"
  ],
  "main": "./lib/index",
  "files": [
    "lib/",
    "dist/"
  ],
  "dependencies": {
    "backo2": "1.0.2",
    "component-bind": "1.0.0",
    "component-emitter": "~1.3.0",
    "debug": "~3.1.0",
    "engine.io-client": "~3.5.0",
    "has-binary2": "~1.0.2",
    "indexof": "0.0.1",
    "parseqs": "0.0.6",
    "parseuri": "0.0.6",
    "socket.io-parser": "~3.3.0",
    "to-array": "0.1.4"
  },
  "devDependencies": {
    "babel-core": "^6.24.1",
    "babel-eslint": "4.1.7",
    "babel-loader": "7.0.0",
    "babel-preset-es2015": "6.24.1",
    "base64-arraybuffer": "^0.1.5",
    "concat-stream": "^1.6.0",
    "derequire": "^2.0.6",
    "eslint-config-standard": "4.4.0",
    "eslint-plugin-standard": "1.3.1",
    "expect.js": "0.3.1",
    "gulp": "^3.9.1",
    "gulp-eslint": "1.1.1",
    "gulp-file": "^0.3.0",
    "gulp-istanbul": "^1.1.1",
    "gulp-mocha": "^4.3.1",
    "gulp-task-listing": "1.0.1",
    "has-cors": "^1.1.0",
    "imports-loader": "^0.7.1",
    "istanbul": "^0.4.5",
    "mocha": "^3.3.0",
    "socket.io": "2.3.0",
    "socket.io-browsers": "^1.0.0",
    "strip-loader": "0.1.2",
    "text-blob-builder": "0.0.1",
    "webpack-merge": "4.1.2",
    "webpack-stream": "3.2.0",
    "zuul": "~3.11.1",
    "zuul-builder-webpack": "^1.2.0",
    "zuul-ngrok": "4.0.0"
  },
  "scripts": {
    "test": "gulp test"
  },
  "contributors": [
    {
      "name": "Guillermo Rauch",
      "email": "rauchg@gmail.com"
    },
    {
      "name": "Arnout Kazemier",
      "email": "info@3rd-eden.com"
    },
    {
      "name": "Vladimir Dronnikov",
      "email": "dronnikov@gmail.com"
    },
    {
      "name": "Einar Otto Stangvik",
      "email": "einaros@gmail.com"
    }
  ],
  "repository": {
    "type": "git",
    "url": "https://github.com/Automattic/socket.io-client.git"
  },
  "license": "MIT"
}
